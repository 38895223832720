<template>
	<MarketplaceProductsShopSection
      v-if="!loading"
    :shopId="shopId"
    :shopInfo="shopInfo"
  />
</template>

<script>
	import MarketplaceProductsShopSection from "./MarketplaceProductsShopSection/MarketplaceProductsShopSection";

	export default {
		name: "MarketplaceProductsShop",
		components: {
      MarketplaceProductsShopSection,
		},

    data(){
		  return {
        shopInfo: {},
        shopId: null,
        loading: true,
      }
    },

    mounted() {

      /**
       * Get id etsy shop of the user
       * @type {{shop_id: string}}
       */

      // filter[shopStatus]=valid&
      this.$store.dispatch('fetchShops', '?filter[type]=12').then(response => {
        this.shopInfo = this._.first(this.getRespPaginateData(response))
        this.shopId = this._.first(this.getRespPaginateData(response))?.id
        this.loading = false
      })
      // this.shopId = this.$route.params.shopId

      // let data = {
      //   "shop_id": this.shopId
      // }

      /**
       * We get shop information and write it down
       */

      // this.$store.dispatch('getMarketplaceProductsHeadShopInfo', data).then((response) => {
      //   if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
      //     this.shopInfo = this.getRespData(response)
      //   } else {
      //     this.$router.push(this.$store.getters.GET_PATHS.mainSettingsMarketplaceProductsHead);
      //     setTimeout(() => {
      //       this.openNotify('error', 'etsyListing_shopError')
      //     }, 1000);
      //   }
      // })
    },
	}
</script>

<style scoped>

</style>
