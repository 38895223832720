<template>
  <div class="fragment">

<!--    state: active, inactive-->

<!--    title: max:255-->

<!--    description: max:255-->

<!--    sku: array string-->

<!--    tags: array string-->

<!--    materials: array string-->

<!--    processing_min: int-->

<!--    processing_max: int-->

<!--    who_made: string-->

<!--    when_made: string-->

<!--    is_supply: bool-->

<!--    is_private: bool-->

<!--    non_taxable: bool-->

<!--    used_manufacturer: bool-->

<!--    is_vintage: bool-->



<!--    item_weight_unit: string (required_with:item_weight)-->

<!--    item_weight: numeric-->

<!--    item_length: numeric-->

<!--    item_width: numeric-->

<!--    item_height: numeric-->

<!--    item_dimensions_unit: string (required_with:item_length, item_width, item_height)-->

<!--    language: "en-US"-->

<!--    taxonomy_path: [-->
<!--      "Clothing",-->
<!--      "Girls' Clothing",-->
<!--      "Sports & Fitness"-->
<!--    ] array string Список категорий-->

<!--    price: numeric-->
<!--    currency_code: USD  Пока только такой код-->
<!--    quantity: int-->

    <div class="cus-section">
      <div class="cus-section__inner">
        <div class="cus-section__info mw-370">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photos'])"></div>
            {{ $t('etsyListing_photos.localization_value.value') }}
<!--            Photos-->
          </div>
          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photosDesc'])"></div>
            <div v-html="$t('etsyListing_photosDesc.localization_value.value')"></div>
<!--            <p>-->
<!--            {{ $t('etsyListing_photosDesc.localization_value.value') }}-->
<!--            </p>-->
          </div>

          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photosDifficult'])"></div>
            {{ $t('etsyListing_photosDifficult.localization_value.value') }}
<!--            Photos *-->
          </div>
          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photosDifficultDesc', 'etsyListing_photosDifficultDesc2'])"></div>
            <p class="mb-0" v-html="$t('etsyListing_photosDifficultDesc.localization_value.value')"></p>
            <p v-html="$t('etsyListing_photosDifficultDesc2.localization_value.value')"></p>
          </div>

          <div class="section-label"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_linkPhotosToVar'])"></div>
            {{ $t('etsyListing_linkPhotosToVar.localization_value.value') }}
<!--            Link photos to variations-->
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_linkPhotosToVarDesc'])"></div>
            <p>
              {{ $t('etsyListing_linkPhotosToVarDesc.localization_value.value') }}
<!--              Add photos to your variations so buyers can see all their options. Try it out-->
            </p>
          </div>

        </div>
        <div class="cus-section__list">
          <PhotoListings
            :MP="MP"
          />
        </div>
      </div>
    </div>

    <div class="cus-section">
      <div class="cus-section__inner">
        <div class="cus-section__info mw-370">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_videos'])"></div>
            {{ $t('etsyListing_videos.localization_value.value') }}
          </div>
          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_videosDesc'])"></div>
            <div v-html="$t('etsyListing_videosDesc.localization_value.value')"></div>
          </div>

        </div>
        <div class="cus-section__list">
          <div class="custom-row w-100">
            <div class="custom-col"
                 v-if="MP.data.Videos.data.maxCountFiles - Object.keys(MP.data.Videos.data.downloadFiles).length > 0"
            >
              <DropZone class="drop-zone-bg"
                        :parentFiles="MP.data.Videos.data.files"
                        @changeImg="changeVideo"
                        :maxSize="MP.data.Videos.data.maxSizeFiles"
                        :maxCount="MP.data.Videos.data.maxCountFiles - Object.keys(MP.data.Videos.data.downloadFiles).length"
                        :accept="'application/octet-stream,video/mp4,video/webm,video/quicktime'"
              />
            </div>
            <div class="custom-row product-img-row ml-4 mt-4"
                 v-if="Object.keys(MP.data.Videos.data.downloadFiles).length > 0"
            >
              <div class="custom-col"
                   v-for="(file, indexFile) in MP.data.Videos.data.downloadFiles"
                   :key="indexFile + 'f1'"
              >
                <div class="position-relative justify-content-center">
                  <div class="site-document__remove" style="right: -25px;"
                       @click="removeFile(MP.data.Videos, indexFile, parseInt(file.id))">
                    <CloseIcon/>
                  </div>
                  <div class="site-document__img">
                    <img src="/img/finance-group/list-no-image.svg" alt="img" class="cursor-initial">
                  </div>
                  <div style="font-size: 14px;">
                    {{file.fileName}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DigitalProduct
        :MP="MP"
    />

    <div class="cus-section">
      <div class="custom-row">
        <div class="custom-col custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_photos','etsyListing_whatIsStatus', 'etsyListing_whatIsStatusAnswer'])"></div>
            {{ $t('etsyListing_status.localization_value.value') }}
            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top-left"
                trigger="hover"
            >
              <TooltipBtn class="top-left"/>
              <template slot="popover">
                <p>
                  <b>
                    {{ $t('etsyListing_whatIsStatus.localization_value.value') }}
                  </b>
                </p>
                <p v-html="$t('etsyListing_whatIsStatusAnswer.localization_value.value')"></p>
              </template>
            </v-popover>
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_activeStatus'])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_activeStatus.localization_value.value')"
                  :name="'params-status'"
                  @change="(val) => {MP.setState(1)}"
                  :checked="MP.data.state === 1"
                  :value="MP.data.state === 1"
              />
            </div>
            <div class="custom-col custom-col--16 custom-col--sxl-33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}"
            >
              <div class="admin-edit" @click="editTranslate(['etsyListing_inactiveStatus'])"></div>
              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_inactiveStatus.localization_value.value')"
                  :name="'params-status'"
                  @change="(val) => {MP.setState(0)}"
                  :checked="MP.data.state === 0"
                  :value="MP.data.state === 0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>


    <InStock
        :MP="MP"
    />


    <div class="cus-section">
      <div class="custom-row">
        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_listingDetails'])"></div>
            {{ $t('etsyListing_listingDetails.localization_value.value') }}
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_title'])"></div>
            <DefaultInput
                :label="$t('etsyListing_title.localization_value.value')"
                v-bind:class="{'ui-no-valid': MP.validation.title}"
                :errorTxt="$t(`${MP.validationTranslate.title}.localization_value.value`)"
                :error="MP.validation.title"
                v-model="MP.data.title"
            />
          </div>
        </div>

        <div class="custom-col custom-col--50 custom-col--md-100"></div>
<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_description', 'etsyListing_description2', 'etsyListing_description3'])"></div>-->
<!--            {{ $t('etsyListing_description.localization_value.value') }}-->
<!--          </div>-->
<!--          {{ $t('etsyListing_description2.localization_value.value') }}-->
<!--          <br>-->
<!--          <br>-->
<!--          {{ $t('etsyListing_description3.localization_value.value') }}-->

<!--        </div>-->

        <div class="custom-col custom-col--50 custom-col--md-100">
          <TextareaDefault
              :label="$t('etsyListing_description.localization_value.value')"
                v-bind:class="{'ui-no-valid': MP.validation.description}"
                :error="MP.validation.description"
                :errorTxt="$t(`${MP.validationTranslate.description}.localization_value.value`)"
                :value="MP.data.description"
                @input="(val) => MP.setDescription(val)"
          />
        </div>

        <div class="custom-col custom-col--50 custom-col--md-100 mb-0">
<!--          <TextareaDefault-->
<!--              :label="$t('etsyListing_descriptionShort.localization_value.value')"-->
<!--              v-bind:class="{'ui-no-valid': MP.validation.descriptionShort}"-->
<!--              :error="MP.validation.descriptionShort"-->
<!--              :errorTxt="$t(`${MP.validationTranslate.descriptionShort}.localization_value.value`)"-->
<!--              :value="MP.data.descriptionShort"-->
<!--              @input="(val) => MP.setDescriptionShort(val)"-->
<!--          />-->
        </div>

<!--        custom-col&#45;&#45;25 custom-col&#45;&#45;sxl-33-->
        <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_SKU', 'etsyListing_SKUDesc', 'etsyListing_SKULink'])"></div>
            {{ $t('etsyListing_SKU.localization_value.value') }}

            <v-popover
                class="site-tooltip site-tooltip--radio-after"
                :disabled="!tooltipAddProductActive"
                offset="5"
                placement="top"
                trigger="hover"

            >

              <TooltipBtn/>

              <template slot="popover">
                <p>
                  <b>{{ $t('etsyListing_SKU.localization_value.value') }}</b>
                </p>
                <p>

                  {{ $t('etsyListing_SKUDesc.localization_value.value') }} <br>
                  <a target="_blank" rel="nofollow" href="https://help.etsy.com/hc/en-us/articles/115015691707?segment=selling">{{$t('etsyListing_SKULink.localization_value.value') }}</a>
                </p>
              </template>
            </v-popover>
          </div>

          <DefaultInput
              :label="$t('etsyListing_SKU.localization_value.value')"
              v-bind:class="{'ui-no-valid': MP.validation.sku}"
              :errorTxt="$t(`${MP.validationTranslate.sku}.localization_value.value`)"
              :error="MP.validation.sku"
              v-model="MP.data.sku"
          />

        </div>


        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_tags'])"></div>
            {{ $t('etsyListing_tags.localization_value.value') }}
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_tagsLabel', 'etsyListing_tagsCaption'])"></div>
            <DefaultInput
                :label="$t('etsyListing_tagsLabel.localization_value.value')"
                :caption="$t('etsyListing_tagsCaption.localization_value.value')"
                :maxLength="45"
                type="text"
                @onEnter="enterTags"
                v-model="tags"
            />

            <div class="d-flex mt-2">
              <div class="tag tag__list">
                <div class="tag__item wfc"
                     v-for="(item, index) in MP.data.tags"
                     :key="index"
                >
                  <div class="tag__remove btn-style"
                    @click="() => MP.removeArrayItem(index,  MP.data.arrayPointer.tags)"
                  ></div>
                  <div class="tag__text">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_material'])"></div>
            {{ $t('etsyListing_material.localization_value.value') }}
          </div>

          <div class="fragment"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_materialLabel', 'etsyListing_tagsCaption'])"></div>
            <DefaultInput
                :label="$t('etsyListing_materialLabel.localization_value.value')"
                :caption="$t('etsyListing_tagsCaption.localization_value.value')"
                v-model="materials"
                @onEnter="enterMaterials"
            />
            <div class="d-flex  mt-2">
              <div class="tag tag__list">
                <div class="tag__item wfc"
                     v-for="(item, index) in MP.data.materials"
                     :key="index"
                >
                  <div class="tag__remove btn-style"
                       @click="() => MP.removeArrayItem(index,  MP.data.arrayPointer.materials)"
                  ></div>
                  <div class="tag__text">
                    {{item}}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100 mb-0">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_dimensions'])"></div>-->
<!--            {{ $t('etsyListing_processing.localization_value.value') }}-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-100 custom-col&#45;&#45;md-50"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_processingMin'])"></div>-->
<!--              <DefaultInput-->
<!--                  :label="$t('etsyListing_processingMin.localization_value.value')"-->
<!--                  :placeholder="'e.g. 10'"-->
<!--                  v-bind:class="{'ui-no-valid': MP.validation.processingMin}"-->
<!--                  :errorTxt="$t(`${MP.validationTranslate.processingMin}.localization_value.value`)"-->
<!--                  :error="MP.validation.processingMin"-->
<!--                  v-model="MP.data.processingMin"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-100 custom-col&#45;&#45;md-50"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_processingMax'])"></div>-->
<!--              <DefaultInput-->
<!--                  :label="$t('etsyListing_processingMax.localization_value.value')"-->
<!--                  :placeholder="'e.g. 10'"-->
<!--                  v-bind:class="{'ui-no-valid': MP.validation.processingMax}"-->
<!--                  :errorTxt="$t(`${MP.validationTranslate.processingMax}.localization_value.value`)"-->
<!--                  :error="MP.validation.processingMax"-->
<!--                  v-model="MP.data.processingMax"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_whoMadeIt'])"></div>-->
<!--            {{ $t('etsyListing_whoMadeIt.localization_value.value') }}-->
<!--          </div>-->

<!--          <div class="fragment"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_whoMadeIt'])"></div>-->
<!--            <DefaultInput-->
<!--                :label="$t('etsyListing_whoMadeIt.localization_value.value')"-->
<!--                v-bind:class="{'ui-no-valid': MP.validation.who_made}"-->
<!--                :errorTxt="$t(`${MP.validationTranslate.who_made}.localization_value.value`)"-->
<!--                :error="MP.validation.who_made"-->
<!--                v-model="MP.data.who_made"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_whenMade'])"></div>-->
<!--            {{ $t('etsyListing_whenMade.localization_value.value') }}-->
<!--          </div>-->

<!--          <div class="fragment"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_whoMadeIt'])"></div>-->
<!--            <DefaultInput-->
<!--                :label="$t('etsyListing_whenMade.localization_value.value')"-->
<!--                v-bind:class="{'ui-no-valid': MP.validation.when_made}"-->
<!--                :errorTxt="$t(`${MP.validationTranslate.when_made}.localization_value.value`)"-->
<!--                :error="MP.validation.when_made"-->
<!--                v-model="MP.data.when_made"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_isSupply', 'etsyListing_typeWhatIs'])"></div>-->
<!--            {{ $t('etsyListing_isSupply.localization_value.value') }}-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_yes'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_yes.localization_value.value')"-->
<!--                  :name="'params-isSupply'"-->
<!--                  :value="MP.data.isSupply"-->
<!--                  :checked="MP.data.isSupply"-->
<!--                  @change="MP.setIsSupply(true)"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_no'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_no.localization_value.value')"-->
<!--                  :name="'params-isSupply'"-->
<!--                  :value="MP.data.isSupply"-->
<!--                  :checked="!MP.data.isSupply"-->
<!--                  @change="MP.setIsSupply(false)"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_isPrivate'])"></div>
            {{ $t('etsyListing_isPrivate.localization_value.value') }}
          </div>


          <div class="custom-row mb-0">
            <div class="custom-col custom-col--33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_yes'])"></div>

              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_yes.localization_value.value')"
                  :name="'params-isPrivate'"
                  :value="MP.data.isPrivate"
                  :checked="MP.data.isPrivate"
                  @change="MP.setIsPrivate(true)"
              />
            </div>
            <div class="custom-col custom-col--33 custom-col--sm-50 custom-col--es-100 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_no'])"></div>

              <LargeRadio
                  class="soft-text mw-100"
                  :label="$t('etsyListing_no.localization_value.value')"
                  :name="'params-isPrivate'"
                  :value="MP.data.isPrivate"
                  :checked="!MP.data.isPrivate"
                  @change="MP.setIsPrivate(false)"
              />
            </div>
          </div>
        </div>

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_nonTaxable'])"></div>-->
<!--            {{ $t('etsyListing_nonTaxable.localization_value.value') }}-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_yes'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_yes.localization_value.value')"-->
<!--                  :name="'params-nonTaxable'"-->
<!--                  :value="MP.data.nonTaxable"-->
<!--                  :checked="MP.data.nonTaxable"-->
<!--                  @change="MP.setNonTaxable(true)"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_no'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_no.localization_value.value')"-->
<!--                  :name="'params-nonTaxable'"-->
<!--                  :value="MP.data.nonTaxable"-->
<!--                  :checked="!MP.data.nonTaxable"-->
<!--                  @change="MP.setNonTaxable(false)"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_usedManufacturer'])"></div>-->
<!--            {{ $t('etsyListing_usedManufacturer.localization_value.value') }}-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_yes'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_yes.localization_value.value')"-->
<!--                  :name="'params-usedManufacturer'"-->
<!--                  :value="MP.data.usedManufacturer"-->
<!--                  :checked="MP.data.usedManufacturer"-->
<!--                  @change="MP.setUsedManufacturer(true)"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_no'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_no.localization_value.value')"-->
<!--                  :name="'params-usedManufacturer'"-->
<!--                  :value="MP.data.usedManufacturer"-->
<!--                  :checked="!MP.data.usedManufacturer"-->
<!--                  @change="MP.setUsedManufacturer(false)"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_isVintage'])"></div>-->
<!--            {{ $t('etsyListing_isVintage.localization_value.value') }}-->
<!--          </div>-->


<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_yes'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_yes.localization_value.value')"-->
<!--                  :name="'params-isVintage'"-->
<!--                  :value="MP.data.isVintage"-->
<!--                  :checked="MP.data.isVintage"-->
<!--                  @change="MP.setIsVintage(true)"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_no'])"></div>-->

<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_no.localization_value.value')"-->
<!--                  :name="'params-isVintage'"-->
<!--                  :value="MP.data.isVintage"-->
<!--                  :checked="!MP.data.isVintage"-->
<!--                  @change="MP.setIsVintage(false)"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->



        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_category'])"></div>
            {{ $t('etsyListing_category.localization_value.value') }}
          </div>

          <div class="custom-row mb-0">
            <div class="custom-col">
              <TreeSelect
                  :multiple="true"
                  :value="MP.data.taxonomyPath"
                  :options="categories"
                  :optionType="'labelName'"
                  @change="val => {MP.data.taxonomyPath = val}"
              />
            </div>
          </div>
        </div>


<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_weightUnit'])"></div>-->
<!--            {{ $t('etsyListing_weightUnit.localization_value.value') }}-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-for="(item, index) in params['item_weight_unit']"-->
<!--                 :key="index"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_' + item + '.localization_value.value')"-->
<!--                  :htmlFix="true"-->
<!--                  :name="'weight-unit'"-->
<!--                  :value="item"-->
<!--                  @change="(val) => (MP.setItemWeightUnit(val))"-->
<!--                  :checked="item === MP.data.itemWeightUnit"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sxl-66 custom-col&#45;&#45;md-100">-->
<!--          <div class="section-label large"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['etsyListing_dimensionsUnit'])"></div>-->
<!--            {{ $t('etsyListing_dimensionsUnit.localization_value.value') }}-->
<!--          </div>-->

<!--          <div class="custom-row mb-0">-->
<!--            <div class="custom-col custom-col&#45;&#45;20 custom-col&#45;&#45;sm-50 custom-col&#45;&#45;md-25 custom-col&#45;&#45;es-100 mb-0"-->
<!--                 v-for="(item, index) in params['item_dimensions_unit']"-->
<!--                 :key="index"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['etsyListing_'+ item])"></div>-->
<!--              <LargeRadio-->
<!--                  class="soft-text mw-100"-->
<!--                  :label="$t('etsyListing_' + item + '.localization_value.value')"-->
<!--                  :htmlFix="true"-->
<!--                  :name="'dimensions-unit'"-->
<!--                  @change="(val) => (MP.setItemDimensionsUnit(val))"-->
<!--                  :checked="item === MP.data.itemDimensionsUnit"-->
<!--                  :value="item"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

        <div class="custom-col custom-col--50 custom-col--sxl-66 custom-col--md-100 mb-0" v-if="!MP.data.isDigitalProduct">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_dimensions'])"></div>
            {{ $t('etsyListing_dimensions.localization_value.value') }}
          </div>

          <div class="custom-row mb-0">
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_weight'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_weight.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': MP.data.Dimensions.validation.weight}"
                  :errorTxt="$t(`${MP.data.Dimensions.validationTranslate.weight}.localization_value.value`)"
                  :error="MP.data.Dimensions.validation.weight"
                  v-model="MP.data.Dimensions.data.weight"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_height'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_height.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': MP.data.Dimensions.validation.height}"
                  :errorTxt="$t(`${MP.data.Dimensions.validationTranslate.height}.localization_value.value`)"
                  :error="MP.data.Dimensions.validation.height"
                  v-model="MP.data.Dimensions.data.height"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_width'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_width.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': MP.data.Dimensions.validation.width}"
                  :errorTxt="$t(`${MP.data.Dimensions.validationTranslate.width}.localization_value.value`)"
                  :error="MP.data.Dimensions.validation.width"
                  v-model="MP.data.Dimensions.data.width"
              />
            </div>
            <div class="custom-col custom-col--25 custom-col--sm-100 custom-col--md-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['etsyListing_length'])"></div>
              <DefaultInput
                  :label="$t('etsyListing_length.localization_value.value')"
                  :placeholder="'e.g. 10'"
                  v-bind:class="{'ui-no-valid': MP.data.Dimensions.validation.dimensionLength}"
                  :errorTxt="$t(`${MP.data.Dimensions.validationTranslate.dimensionLength}.localization_value.value`)"
                  :error="MP.data.Dimensions.validation.dimensionLength"
                  v-model="MP.data.Dimensions.data.dimensionLength"
              />
            </div>
          </div>

        </div>



        <div class="custom-col custom-col--12-5 custom-col--sxl-33 custom-col--md-50">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_price', 'etsyListing_priceDesc'])"></div>
            {{ $t('etsyListing_price.localization_value.value') }}
          </div>

          <InputSum
              class="mw-138"
              :icoType="'dollar'"
              :placeholder="'0'"
              v-bind:class="{'ui-no-valid': MP.validation.price}"
              :errorTxt="$t(`${MP.validationTranslate.price}.localization_value.value`)"
              :error="MP.validation.price"
              v-model="MP.data.price"
          />
        </div>

        <div class="custom-col custom-col--12-5 custom-col--sxl-33 custom-col--md-50">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_qTy', 'etsyListing_qTyDesc'])"></div>
            {{ $t('etsyListing_qTy.localization_value.value') }}
          </div>
          <InputQuantity
              :label="$t('common_ItemsQty.localization_value.value')"
              v-model="MP.data.quantity"
              v-bind:class="{'ui-no-valid': MP.validation.quantity}"
              :error="MP.validation.quantity"
              :errorTxt="$t(`${MP.validationTranslate.quantity}.localization_value.value`)"
              @change="(val) => MP.setQuantity(val)"
          />

        </div>

      </div>
    </div>

    <div class="cus-section" v-if="isEdit">
      <div class="custom-row">

        <div class="custom-col">
          <div class="section-label large"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['etsyListing_variations'])"></div>
            {{ $t('etsyListing_variations.localization_value.value') }}
          </div>
        </div>

        <div class="custom-col custom-col--50"
             v-for="(item, index) in MP.data.MarketplaceVariations"
             :key="index">

          <div class="custom-row">

            <div class="custom-col custom-col--20 custom-col--sxl-33 custom-col--md-50" v-if="item.data.variationName">
              <div class="section-label large"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                {{ item.data.variationName }}
              </div>

              <DefaultInput
                  class="mw-138"
                  :placeholder="''"
                  v-model="item.data.variationValue"
                  :disabled="true"
              />
            </div>

            <div class="custom-col custom-col--20 custom-col--sxl-33 custom-col--md-50" v-if="item.data.variation2Name">
              <div class="section-label large"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                {{ item.data.variation2Name }}
              </div>

              <DefaultInput
                  class="mw-138"
                  :placeholder="''"
                  v-model="item.data.variation2Value"
                  :disabled="true"
              />
            </div>

            <div class="custom-col custom-col--20 custom-col--sxl-33 custom-col--md-50">
              <div class="section-label large"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_price', 'etsyListing_priceDesc'])"></div>
                {{ $t('etsyListing_price.localization_value.value') }}
              </div>

              <InputSum
                  class="mw-138"
                  :icoType="'dollar'"
                  :placeholder="'0'"
                  v-bind:class="{'ui-no-valid': item.validation.price}"
                  :errorTxt="$t(`${item.validationTranslate.price}.localization_value.value`)"
                  :error="item.validation.price"
                  v-model="item.data.price"
              />
            </div>

            <div class="custom-col custom-col--20 custom-col--sxl-33 custom-col--md-50">
              <div class="section-label large"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_qTy'])"></div>
                {{ $t('etsyListing_qTy.localization_value.value') }}
              </div>

              <DefaultInput
                  class="mw-138"
                  :placeholder="''"
                  v-bind:class="{'ui-no-valid': item.validation.quantity}"
                  :errorTxt="$t(`${item.validationTranslate.quantity}.localization_value.value`)"
                  :error="item.validation.quantity"
                  v-model="item.data.quantity"
              />
            </div>

            <div class="custom-col custom-col--12-5 custom-col--sxl-33 custom-col--md-50">
              <div class="section-label large"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['etsyListing_qTy'])"></div>
                {{ $t('etsyListing_Visible.localization_value.value') }}
              </div>

              <DefaultCheckbox
                  class="visible-checkbox"
                  v-model="item.data.visible"
                  :label="''"
                  @click="item.data.visible = !item.data.visible"
              />
            </div>
          </div>

        </div>


      </div>
    </div>

  </div>
</template>

<script>
import LargeRadio from "../../../../../UI/radiobuttons/LargeRadio/LargeRadio";
import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import PhotoListings from "../PhotoListings/PhotoListings";
// import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";
// import CategoryPopup from "../../../popups/CategoryPopup/CategoryPopup";
// import Taxonomy from "../Taxonomy/Taxonomy";
import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
import TreeSelect from "@/components/UI/selectiones/TreeSelect/TreeSelect";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import DropZone from "@/components/coreComponents/DropZone/DropZone";
import DigitalProduct
  from "@/components/modules/MarketplaceProductsModule/components/chunks/DigitalProduct/DigitalProduct";
import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
import InStock from "@/components/modules/MarketplaceProductsModule/components/chunks/InStock/InStock";

export default {
  name: "StepContent",
  components: {
    InStock,
    DigitalProduct,
    DropZone,
    DefaultCheckbox,
    TreeSelect,
    TextareaDefault,
    // Taxonomy,
    LargeRadio,
    TooltipBtn,
    DefaultInput,
    PhotoListings,
    // DefaultSelect,
    InputSum,
    InputQuantity,
    // CategoryPopup,
    CloseIcon,
  },

  props: {
    MP: Object,
    categories: Array,
    isEdit: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      tooltipAddProductActive: true,
      isModalCategoryPopup: false,
      tags: '',
      materials: '',

      params: {
        item_dimensions_unit: {
          in: "in", ft: "ft", mm: "mm", cm: "cm", m: "m"
        },
        item_weight_unit: {
          oz: "oz", lb: "lb", g: "g", kg: "kg"
        }
      }
    }
  },

  methods: {


    changeCategoryPopup(val){
      this.isModalCategoryPopup = val
    },

    enterMaterials(){
      this.materials = this.MP.addArrayItem(this.materials, 'materials')
    },
    enterTags(){
      this.tags = this.MP.addArrayItem(this.tags, 'tags')
    },

    changeVideo(files, maxSizeError) {

      this.MP.data.Videos.setFiles(files)

      this.MP.data.Videos.setFilesMaxSizeError(maxSizeError)
    },

    removeFile(fileModel, index, id) {
      this.$store.dispatch('removeUserDocument', id).then((response) => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            fileModel.data.downloadFiles.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.mw-138 {
  max-width: 138px;
}

.visible-checkbox {
  position: relative;
  top: 13px;
  left: 0;
}
</style>
